.item_icon[data-v-d8112380] {
  display: inline-block;
  width: 58%;
  padding: 1px 3px;
  padding-right: 10px;
  cursor: pointer;
}
.item_icon i[data-v-d8112380] {
  display: inline-block;
  line-height: 13px;
  font-size: 13px;
}
.item_icon .iconfont icon-cuowu[data-v-d8112380] {
  color: #f56c6c;
}
.item_icon .iconfont icon-duihao[data-v-d8112380] {
  color: #67c23a;
}
.item_icon .el-icon-circle-check[data-v-d8112380] {
  color: #67c23a;
}
.item_icon .iconfont icon-info[data-v-d8112380] {
  color: #409eff;
}
.item_icon .iconfont icon-cuowu[data-v-d8112380] {
  color: #e6a23c;
}
.item_icon .iconfont icon-cuowu-outline[data-v-d8112380] {
  color: #e6a23c;
}
.item_icon span[data-v-d8112380] {
  display: inline-block;
  font-size: 14px;
  line-height: 28px;
  color: #333;
  margin-left: 4px;
}
.title[data-v-d8112380] {
  background-color: #fef1ed;
  font-size: 13px;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  letter-spacing: 2px;
  color: red;
}