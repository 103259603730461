.left_box[data-v-e0e3db8c] {
  float: left;
  width: 2%;
}
.left_box input[data-v-e0e3db8c] {
  width: 80%;
  height: 16px;
}
.right_btns[data-v-e0e3db8c] {
  width: 3%;
  float: right;
  text-align: right;
  margin-bottom: 10px;
}
.top_title[data-v-e0e3db8c] {
  display: flex;
  width: 100%;
}
.right_box[data-v-e0e3db8c] {
  width: 100%;
}
.top-nav[data-v-e0e3db8c] {
  height: 36px;
  background: var(--themeColor, #17a2b8);
  color: #fff;
  margin-bottom: 10px;
  font-weight: 500;
  position: relative;
  border-bottom: 1px solid #b9b9b9;
}
.top-nav .box-content[data-v-e0e3db8c] {
  box-sizing: border-box;
  text-align: center;
  line-height: 34px;
  font-size: 13px;
  height: 34px;
}
.bottom-nav[data-v-e0e3db8c] {
  height: 36px;
  font-size: 13px;
  position: relative;
  color: #212529;
}
.bottom-nav .box-content[data-v-e0e3db8c] {
  box-sizing: border-box;
  text-align: center;
  border-left: 1px solid #b9b9b9;
  line-height: 34px;
  font-size: 13px;
  height: 34px;
}
.table_height[data-v-e0e3db8c] {
  overflow-y: overlay;
  overflow-x: hidden;
}
.content_center[data-v-e0e3db8c] {
  display: flex;
  position: relative;
}
.content-nav[data-v-e0e3db8c] {
  line-height: 36px;
  border-bottom: none;
  color: #212529;
}
.content-nav .content-top[data-v-e0e3db8c] {
  background: var(--themeColorLeftMenu, #ecf7f9);
}
.content-nav .content-top .box-content[data-v-e0e3db8c] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
}
.content-nav .content-top .box-content[data-v-e0e3db8c]:first-child(1) {
  border-left: none;
}
.content-nav .for-content .box-content[data-v-e0e3db8c] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
  padding: 0 10px;
}
.content-nav .for-content .el-col:nth-child(1) .box-content[data-v-e0e3db8c] {
  border-left: none;
}
.content-nav .bottom-content .box-content[data-v-e0e3db8c] {
  box-sizing: border-box;
  text-align: center;
  line-height: 36px;
  height: 36px;
  font-size: 13px;
}
.content-nav .bottom-content .el-col:nth-child(1) .box-content[data-v-e0e3db8c] {
  border-left: none;
}
.top_font[data-v-e0e3db8c] {
  float: left;
  width: 83%;
  margin: 0 auto;
}
.top_font li[data-v-e0e3db8c] {
  font-size: 13px;
  color: #212529;
  float: left;
  text-align: left;
  padding-left: 15px;
  margin-right: 10px;
}
.top_right_btns[data-v-e0e3db8c] {
  float: right;
  margin-right: 10px;
}
.top_right_btn2[data-v-e0e3db8c] {
  float: right;
  margin-right: 40px;
}
.top_right_btn2 i[data-v-e0e3db8c] {
  margin: 0 5px;
  cursor: pointer;
  color: var(--themeColor, #17a2b8);
  font-size: 17px;
  line-height: 24px;
}
.el-col-9[data-v-e0e3db8c] {
  border-right: 1px solid #b9b9b9;
}
.el-col-9 div[data-v-e0e3db8c] {
  padding-left: 10px;
  box-sizing: border-box;
}
.el-col-3[data-v-e0e3db8c] {
  border-right: 1px solid #b9b9b9;
  text-align: right;
  padding-right: 10px;
}
.el-col-18[data-v-e0e3db8c] {
  border-right: 1px solid #b9b9b9;
  padding-left: 10px;
}
.content_center:last-child .content-nav[data-v-e0e3db8c] {
  border-bottom: 1px solid #b9b9b9;
}
.el-row[data-v-e0e3db8c] {
  display: flex;
  border: 1px solid #b9b9b9;
  border-bottom: none;
}
.bottom-nav .el-col-3[data-v-e0e3db8c]:last-child {
  border-right: none;
}
.top_right_btns[data-v-e0e3db8c] .el-icon {
  color: var(--themeColor, #17a2b8);
  font-size: 17px;
}
.text_none[data-v-e0e3db8c] {
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.pic[data-v-e0e3db8c] {
  position: absolute;
  right: 100px;
  z-index: 3;
  top: 25px;
  opacity: 0.3;
}
.iconfont[data-v-e0e3db8c] {
  font-size: 17px;
}
.ccyc[data-v-e0e3db8c] {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fold_icon[data-v-e0e3db8c] {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
  padding: 0 10px;
  border-radius: 14px;
  font-size: 11px !important;
  height: 20px;
  line-height: 20px;
}
.fold_icon.not-table-show-btn[data-v-e0e3db8c] {
  bottom: unset;
  right: unset;
  top: -10px;
  left: 57%;
  transform: translateX(-50%);
}
[data-v-e0e3db8c] .el-table .el-table__cell {
  z-index: unset;
}