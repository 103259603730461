.el-table[data-v-e0e3db8c] .cell {
  color: #212529;
  overflow: unset;
}
.none-border p[data-v-e0e3db8c] {
  line-height: 30px;
  text-align: left;
  border-top: 1px solid #b9b9b9;
  text-indent: 1em;
  height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.none-border p[data-v-e0e3db8c]:first-child {
  border-top: none;
}
.top_btns[data-v-e0e3db8c] {
  margin-bottom: 38px;
}
.top_btns .left_btns[data-v-e0e3db8c] {
  float: left;
  display: flex;
  align-items: center;
}
.top_btns .right_btn[data-v-e0e3db8c] {
  float: right;
  text-align: right;
}
.styleForm[data-v-e0e3db8c] {
  margin: 21px 0px 0px 16px;
  width: 430px;
}
.styleForm .el-select[data-v-e0e3db8c] {
  margin-left: 0px;
}
.bottom_button[data-v-e0e3db8c] {
  display: inline-block;
  margin-top: 20px;
}