.table-big[data-v-7b219a52] {
  width: 100%;
  height: 620px;
  overflow: auto;
  margin-top: 10px;
  overflow: auto;
}
.table-big .table[data-v-7b219a52] {
  min-width: 1360px;
}
.table-big .table .table-div[data-v-7b219a52] {
  display: inline-block;
}
.tableHead3[data-v-7b219a52] {
  display: inline-block;
  width: 100px;
  text-align: center;
  border-right: 1px solid #eee;
  height: 80px;
  overflow-x: auto;
}
.tableHead[data-v-7b219a52] {
  display: inline-block;
  width: 100px;
  text-align: center;
  border-right: 1px solid #eee;
  height: 30px;
}
.tableHead4[data-v-7b219a52] {
  display: inline-block;
  width: 360px;
  text-align: center;
  border-right: 1px solid #eee;
  height: 30px;
}
.tableHead2[data-v-7b219a52] {
  display: inline-block;
  width: 360px;
  text-align: center;
  border-right: 1px solid #eee;
  height: 80px;
  overflow-x: auto;
}