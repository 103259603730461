
.none-border .cell {
  padding: 0;
}
.none-border .el-table__row td {
  padding: 0 !important;
}
.none-border .cell {
  padding: 0;
  text-align: center;
}
.width120 {
  width: 120px;
}
.mr-10 {
  margin-right: 10px;
}
.w-110 {
  width: 110px;
.el-input-number__decrease, .el-input-number__increase{
    display: none!important;
}
.el-input-number .el-input__inner{
    text-align: left!important;
}
}
.w-234 {
  width: 234px;
}
.mr-4 {
  margin-right: 4px;
}
.change-style {
  cursor: pointer;
  margin-left: 10px;
  font-size: 13px;
}
