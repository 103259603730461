.title[data-v-4cee2a62] {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.top_select[data-v-4cee2a62] {
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}